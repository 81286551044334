<template>
  <div
    v-infinite-scroll="loadMore"
    infinite-scroll-disabled="busy"
    infinite-scroll-distance="10"
  >
    <r-page page-title="Employment">
      <template #page-header>
        <h2 class="page-title">Employee</h2>
        <r-menu-tab :level="2" />
      </template>
      <template #action-bar>
        <div class="level">
          <div class="level-left">
            <b-button
              tag="router-link"
              class="is-command"
              icon-left="plus"
              to="/employment/create"
            >
              New
            </b-button>
          </div>
          <div class="level-right">
            <b-field class="employee-search-container">
              <b-input
                expanded
                v-model="search"
                placeholder="Search"
                type="text"
                @keydown.native.enter="searchIconClick"
                icon-clickable
                icon-right="magnify"
                @icon-right-click="searchIconClick"
                icon-right-clickable
                :loading="isEmployeeContractListLoading"
              ></b-input>
            </b-field>
          </div>
        </div>
      </template>

      <template #page-content>
        <div id="table-request-employment">
          <b-table
            :data="employeeContractList"
            :per-page="perPage"
            :loading="isEmployeeContractListLoading"
            @page-change="onPageChange"
            :show-detail-icon="showDetailIcon"
            ref:table
            hoverable
            narrowed
            backend-sorting
            :default-sort-direction="defaultSortOrder"
            :default-sort="[sortField, sortOrder]"
            checkable
            checkbox-position="left"
            :checked-rows.sync="checkedRows"
            class="employment-directory-table"
            @sort="onSort"
            :sticky-header="stickyHeaders"
          >
            <template>
              <b-table-column
                field="users.first_name"
                label="Employee's Name"
                v-slot="props"
                sortable
                width="27%"
              >
                <div class="columns employment-table-name">
                  <img
                    class="employment-profile-picture"
                    :src="
                      determineProfilePicture(
                        props.row.profilePicture,
                        props.row.gender
                      )
                    "
                  />
                  <span class="is-capitalize">
                    {{ props.row.name }}
                  </span>
                </div>
              </b-table-column>

              <b-table-column
                field="employments.contract_type"
                label="Employment Status"
                v-slot="props"
                sortable
                width="14%"
              >
                <span class="is-capitalize">
                  {{ props.row.contractType }}
                </span>
              </b-table-column>

              <b-table-column
                field="employments.type_of_worker"
                label="Type of Worker"
                v-slot="props"
                sortable
                width="14%"
              >
                <span class="is-capitalize">
                  {{ props.row.typeOfWorker }}
                </span>
              </b-table-column>

              <b-table-column
                field="employments.join_date"
                label="Join Date"
                v-slot="props"
                sortable
                width="15%"
              >
                <span class="is-capitalize">
                  {{ formatDate(props.row.joinDate) }}
                </span>
              </b-table-column>

              <b-table-column
                field="employments.start_contract_at"
                label="Start Contract"
                v-slot="props"
                sortable
                width="15%"
              >
                <span class="is-capitalize">
                  {{ formatDate(props.row.startContractAt) }}
                </span>
              </b-table-column>

              <b-table-column
                field="employments.end_contract_at"
                label="End Contract"
                v-slot="props"
                sortable
                width="15%"
              >
                <span class="is-capitalize">
                  {{ formatDate(props.row.endContractAt) }}
                </span>
              </b-table-column>
            </template>
            <template slot="empty">
              <section class="section">
                <div class="content has-text-grey has-text-centered">
                  <p>No data found</p>
                </div>
              </section>
            </template>
          </b-table>
        </div>
        <div class="has-text-centered" v-if="isLoadMore">Please Wait ...</div>
      </template>
    </r-page>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import moment from 'moment'

export default {
  computed: {
    ...mapGetters({
      employeeContractList: 'employment/getEmployeeContractList',
    }),
  },
  data() {
    return {
      checkedRows: [],
      isRowCheckable: true,
      stickyHeaders: true,

      isPaginated: false,
      perPage: 20,
      page: 0,
      lastPage: 0,

      defaultSortOrder: 'asc',
      sortField: 'users.first_name',
      sortOrder: 'asc',
      search: '',

      showDetailIcon: true,

      isEmployeeContractListLoading: false,
      isLoadMore: false,
    }
  },
  methods: {
    ...mapActions({
      actionLoadEmployeeContractList: 'employment/fetchEmployeeListContract',
    }),

    ...mapMutations({
      setEmployeeListsContract: 'employment/setEmployeeListContract',
    }),

    async loadMore() {
      if (this.page < this.lastPage) {
        this.isLoadMore = true
        await this.loadEmployeeContractList()
        this.isLoadMore = false
      }
    },

    async searchIconClick() {
      await this.resetState()
    },

    async resetState() {
      this.page = 0
      this.lastPage = 0
      this.setEmployeeListsContract([])
      await this.loadEmployeeContractList()
    },

    onSort(field, order) {
      this.sortField = field
      this.sortOrder = order
      this.resetState()
    },

    onPageChange(page) {
      this.page = page
      this.loadEmployeeContractList()
    },

    formatDate(date) {
      if (date) {
        return moment(date).format('D MMM YYYY')
      }
    },

    async loadEmployeeContractList() {
      this.isEmployeeContractListLoading = true
      try {
        const response = await this.actionLoadEmployeeContractList({
          perPage: this.perPage,
          page: ++this.page,
          sortField: this.sortField,
          sortOrder: this.sortOrder,
          search: this.search,
        })

        this.total = response.data.meta.total
        this.lastPage = response.data.meta.lastPage
      } catch (err) {
        console.log(err)
      }
      this.isEmployeeContractListLoading = false
    },

    determineProfilePicture(user, gender) {
      if (!user) {
        if (gender === 'M') {
          // male
          return '/images/default-profile-picture-male.png'
        }
        // female
        return '/images/default-profile-picture-female.png'
      }

      return user
    },
  },
  async mounted() {
    this.setEmployeeListsContract([])
    await this.loadEmployeeContractList()
  },
}
</script>
